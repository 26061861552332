import { Button, Card, Table } from "antd";
import { Badge } from "evergreen-ui";
import { StatusColor } from "../../../../shared/helpers/functions";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
function KycUsersDatatable(props: any) {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: 500,
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 500,
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: "PHONE",
      dataIndex: "mobile",
      key: "mobile",
      width: 500,
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: "CITY",
      dataIndex: "city",
      key: "city",
      width: 500,
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "STATUS",
      dataIndex: "isissued",
      key: "isissued",
      width: 500,
      render: (record: any) => {
        return (
          <div className="table-Txt">
            <Badge style={{ color: StatusColor(record) }}>{record}</Badge>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 100,
      key: "action",
      render: (_: any, record: any) => {
        return (
          <div className="d-flex align-items-center">
            <AiOutlineEdit
              size={20}
              className="me-3 cursor-pointer text-blue-500 hover:text-blue-600"
              onClick={() => navigate(`/admin/kycVerification/${record?.userId}`)}
            />
            <Button
              onClick={() =>
                navigate(`/admin/kycdetails/${btoa(record?.userId)}`)
              }>
              Issue KYC
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Card>
      <Table
        loading={props?.isLoading}
        columns={columns}
        dataSource={props?.data}
        pagination={false}
        size="small"
        bordered
      />
    </Card>
  );
}

export default KycUsersDatatable;
