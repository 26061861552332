import { Button, Form, Modal, notification } from "antd";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import { useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../../../Redux/slice/authSlice";
import ImagePicker from "../../../../components/imagePicker/index";
import API from "../../../../config/api";
import { COMPRESS_IMAGE, PUT } from "../../../../utlis/apiCalls";
import ImageHandler from "../../../../components/imageHandler";

const EditProfilePhoto = (props: any) => {
  const User = useSelector((state: any) => state.auth.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState<any>({});
  const fileInputRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageCropped, setIsImageCropped] = useState(false);

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedData = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCroppedImage(croppedData);
      setCropModalOpen(false);
      setIsImageCropped(true);
    }
  };

  const formSubmitHandler = async (values: any) => {
    setIsLoading(true);
    const url = API.USER_EDIT + User?.id;
    try {
      let imageUrl: any;
      if (croppedImage) {
        const ImageBlob = await fetch(croppedImage).then((r) => r.blob());
        console.log(ImageBlob);
        const formData = new FormData();
        let name = moment(new Date()).unix() + "N.jpg";
        let file = new File([ImageBlob], name, { type: name });
        formData.append("file", file);
        imageUrl = await COMPRESS_IMAGE(file);
      }

      const obj = {
        image: imageUrl.url,
      };
      const responseImg: any = await PUT(url, obj);
      if (responseImg) {
        notificationApi.success({
          message: t("profile_picture_updated_successfully"),
        });
        dispatch(isLoggedIn(responseImg?.data));
        setTimeout(() => {
          form.resetFields();
          props?.close();
          setImage({});
          setCroppedImage(null);
        }, 1000);
      } else {
        notificationApi.error({ message: responseImg });
      }
    } catch (err: any) {
      console.log(err);
      notificationApi.error({
        message: err.message,
      });
    }
    setIsLoading(false);
  };
  const removeProfilePicture = async () => {
    setIsLoading(true);
    const url = API.USER_EDIT_REMOVE_PROFILE + User?.id;
    try {
      const obj = {
        image: "",
      };
      const responseImg: any = await PUT(url, obj);
      if (responseImg) {
        notificationApi.success({
          message: t("profile_picture_removed_successfully"),
        });
        dispatch(isLoggedIn(responseImg));
        setTimeout(() => {
          form.resetFields();
          props?.close();
          setImage({});
          setCroppedImage(null);
        }, 1000);
      } else {
        notificationApi.error({ message: responseImg });
      }
    } catch (err: any) {
      console.log(err);
      notificationApi.error({
        message: err.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={props?.open}
      onCancel={() => {
        props?.close();
        setImage({});
        setCropModalOpen(false);
        setIsImageCropped(false);
        setCroppedImage(null);
      }}
      centered
      footer={false}
      className="app-ProfileScreen-Section2-UpdateModal"
    >
      <br />
      <div className="app-ProfileScreen-txt6">{t("upload_profile_image")}</div>
      <div className="app-ProfileScreen-UpdateModal-Txt1">
        For best results, Image ulpoads should be in png or jpg format.
      </div>
      <br />
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
      >
        {cropModalOpen ? (
          <div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setImage({
                    file: selectedFile,
                    url: URL.createObjectURL(selectedFile),
                  });
                  setCropModalOpen(true);
                }
              }}
            />
            <Cropper
              ref={cropperRef}
              src={image?.url}
              aspectRatio={1 / 1}
              guides={true}
            />
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 16 }}
            >
              <Button
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as any).click();
                  }
                }}
                style={{
                  marginRight: 16,
                  backgroundColor: "transparent",
                  color: "#000",
                  borderRadius: "20px",
                }}
              >
                {t("choose_another_image")}
              </Button>
              <Button
                type="primary"
                onClick={handleCrop}
                style={{ borderRadius: "20px" }}
              >
                {t("crop_image")}
              </Button>
              <div className="mb-2"></div>
            </div>
          </div>
        ) : (
          <>
            <Form.Item
              name={"image"}
              rules={[
                {
                  required: true,
                  message: t("please_upload_image"),
                },
              ]}
            >
              <ImageHandler
                onChange={(file: any) => {
                  setImage(file);
                  setCropModalOpen(true);
                  setIsImageCropped(false);
                }}
                fileURL={
                  croppedImage ?? (User?.image ? User?.image : User?.image)
                }
              />
            </Form.Item>
            {User?.image && !isImageCropped && !croppedImage ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  color: "#000",
                  cursor: "pointer",
                }}
                onClick={removeProfilePicture}
              >
                <Button
                  style={{
                    backgroundColor: "red",
                    border: "none",
                    borderRadius: "20px",
                    height: "40px",
                  }}
                >
                  <MdDeleteOutline size={20} />
                  Remove current picture
                </Button>
              </div>
            ) : null}
          </>
        )}
        <div className="d-flex gap-2 justify-content-end">
          {isImageCropped && (
            <>
              <Button
                onClick={() => {
                  props?.close();
                  setImage({});
                  setCropModalOpen(false);
                  setIsImageCropped(false);
                  setCroppedImage(null);
                }}
                style={{ borderRadius: "20px", color: "#000" }}
              >
                {t("cancel")}
              </Button>
              <Button
                type="primary"
                loading={isLoading}
                onClick={form.submit}
                style={{ borderRadius: "20px" }}
              >
                {t("submit")}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default EditProfilePhoto;
