import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentCurrency } from "../../../Redux/slice/currencyInfoSlice";
import { useTranslation } from "react-i18next";
import { IoLocation } from "react-icons/io5";

export default function Currency(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currencyInfo = useSelector(
    (state: any) => state?.currencyInfo?.currencyInfo
  );
  const currentCurrency = useSelector(
    (state: any) => state?.currencyInfo?.current
  );
  const options = currencyInfo?.map((item: any) => ({
    value: item.id,
    label: item.country_name,
  }));
  const onChange = (value: string) => {
    if (value !== currentCurrency?.id) {
      const selected = currencyInfo?.find((item: any) => item?.id == value);
      dispatch(changeCurrentCurrency(selected));
    }
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Select
      style={{ width: 200 }}
      bordered={false}
      showSearch
      optionFilterProp="children"
      defaultValue={currentCurrency?.id}
      value={currentCurrency?.id}
      onChange={onChange}
      filterOption={filterOption}
      options={options}
      suffixIcon={<IoLocation size={20} color="#00a56e" />}
      size="small"
    />
  );
}
