import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  Timeline,
  message,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { LuRefreshCcw } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useParams } from "react-router-dom";
import API from "../../../config/api";
import { changeMetalTypeFormat } from "../../../shared/helpers/functions";
import { GET, PUT } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import "../trading/styles.scss";
import { useTranslation } from "react-i18next";

export default function SipDetails() {
  const param = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [tradeDetails, setTradeDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState<string>();
  const [tradeHistory, setTradeHistory] = useState<any>([]);
  useEffect(() => {
    getSipHistory();
  }, [refresh]);

  const getSipHistory = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.SIP_HISTORY + param.id, null);
      console.log("responsexcvbnm,", response);
      if (response?.status) {
        let history: any = [];
        response?.data?.map((item: any) => {
          let dot: any;
          if (item?.status === "pending") {
            dot = <AiOutlineClockCircle color="blue" />;
          } else if (
            item?.status === "accepted" ||
            item?.status === "Wallet Updated"
          ) {
            dot = <TiTick color="green" />;
          } else if (item?.status === "rejected" || item?.status === "cancelled") {
            dot = <RxCrossCircled color="red" />;
          }
          history.push({
            label: item?.status,
            children: item?.message,
            dot: dot,
          });
          setTradeDetails(item);
          setTradeHistory(history);
        });
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const sorryInvoice = () => {
    message.info("The invoice download feature is coming soon. Stay tuned!");
  };
  const statusChange = async (value: any) => {
    try {
      setIsLoading2(true);
      const obj = {
        status,
        comment: value?.comment,
        type: tradeDetails?.type,
      };
      const response: any = await PUT(API.SIP_STATUS + param.id, obj);
      if (response?.status) {
        notificationApi.success({
          message: `successfully changed status to ${status}`,
        });
        setRefresh(!refresh);
        setIsModalOpen(false);
      } else {
        notificationApi.error({
          message: response?.message,
        });
      }
    } catch (error) {
    } finally {
      setIsLoading2(false);
    }
  };

  const tradeDetailsNew = tradeDetails;
  console.log("tradeDetailsNew", tradeDetailsNew);
  const walletAlert = () => {
    if (
      tradeDetails?.type === "buy" &&
      tradeDetails?.amount > tradeDetails?.balance &&
      tradeDetails?.payment_type === "wallet"
    ) {
      return (
        <Alert
          message={
            <div style={{ color: "red" }}>
             {t("Wallet_Alert")}
            </div>
          }
          description={
            <div>
              <Row>
                <Col>
                  {" "}
                  <div>{t("Customer_WalletBalance")} : </div>
                </Col>
                <Col>
                  <div>{tradeDetailsNew?.balance?.toFixed(2)}</div>{" "}
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <div>{t("Trade_Amount")} : </div>
                </Col>
                <Col>
                  <div>{tradeDetailsNew?.amount?.toFixed(2)}</div>{" "}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  {" "}
                  <div>{t("balanceAmount_Required")} : </div>
                </Col>
                <Col>
                  <div>
                    {(
                      tradeDetailsNew?.amount - tradeDetailsNew?.balance
                    ).toFixed(2)}
                  </div>{" "}
                </Col>
              </Row>
              <br />
            </div>
          }
          type="error"
        />
      );
    } else if (
      tradeDetails?.type === "sell" &&
      tradeDetails?.weight > tradeDetails?.balance
    ) {
      return (
        <Alert
          message={
            <div style={{ color: "red" }}>
              {t("Insufficent")}
            </div>
          }
          description={
            <div>
              <Row>
                <Col>
                  {" "}
                  <div>{t("Customer_WalletBalance")} : </div>
                </Col>
                <Col>
                  <div>{tradeDetails?.balance?.toFixed(2)}</div>{" "}
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <div>{t("Trade_Weight")} : </div>
                </Col>
                <Col>
                  <div>{tradeDetails?.weight?.toFixed(2)}</div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  {" "}
                  <div>{t("balanceWeight_Required")} : </div>
                </Col>
                <Col>
                  <div>
                    {(tradeDetails?.weight - tradeDetails?.balance).toFixed(2)}
                  </div>
                </Col>
              </Row>
            </div>
          }
          type="error"
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={t("Sip_Details")}>
          <div className="d-flex">
            <div>
              <Button type="primary" onClick={sorryInvoice}>
                {t("GenerateInvoice")}
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                disabled={tradeDetailsNew?.status === "pending" ? false : true}
                type="primary"
                color="green"
                onClick={() => {
                  setStatus("accepted");
                  setIsModalOpen(true);
                }}
              >
                {t("Accept")}
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                disabled={tradeDetailsNew?.status === "pending" ? false : true}
                type="primary"
                danger
                onClick={() => {
                  setStatus("rejected");
                  setIsModalOpen(true);
                }}
              >
                {t("Reject")}
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <LuRefreshCcw size={20} />
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <Card loading={isLoading}>
            <Row>
              <Col sm={6}>
                {" "}
                <div style={{ marginLeft: 50 }}>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Name :</span>
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.user?.name}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Metal Type :</span>{" "}
                    <span className="tradeDetails-text2">
                      {changeMetalTypeFormat(tradeDetailsNew?.metal_type)}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Target Weight :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.sip?.target_weight}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Tenure :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.sip?.tenure}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Interval :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.sip?.interval}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">
                      Purchased Amount :
                    </span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.purchased_amount}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Weight :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.weight}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Type :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.type}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Payment Type :</span>{" "}
                    <span className="tradeDetails-text2">
                      {changeMetalTypeFormat(tradeDetailsNew?.payment_type)}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Status :</span>{" "}
                    <span className="tradeDetails-text2">
                      {tradeDetailsNew?.status == "accepted"
                        ? "Accepted"
                        : tradeDetailsNew?.status}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="tradeDetails-text1">Create Date :</span>{" "}
                    <span className="tradeDetails-text2">
                      {moment(tradeDetailsNew?.createdAt)
                        .utcOffset(moment(tradeDetails?.createdAt).utcOffset())
                        .format("DD/MMM/YYYY h:mm A")}
                    </span>
                  </div>
                  {/* <div className="mb-3">
                <span className="tradeDetails-text1">Ivoice Id</span>:
                <span className="tradeDetails-text2">
                  {tradeDetails?.invoice_id ?? "unset"}
                </span>
              </div> */}
                </div>
              </Col>
              <Col sm={6}>
                <Timeline
                  style={{ textTransform: "capitalize" }}
                  mode="alternate"
                  items={tradeHistory}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <Modal
        onOk={form.submit}
        title={`Are You Sure to ${status} Trade`}
        open={isModalOpen}
        okButtonProps={{ loading: isLoading2 }}
        cancelButtonProps={{ loading: isLoading2 }}
        onCancel={() => setIsModalOpen(false)}
      >
        {walletAlert()}
        <div style={{ height: 20 }} />
        <Form onFinish={statusChange} form={form}>
          <Form.Item
            label="comment"
            name="comment"
            rules={[{ required: true, message: "Please input your Comment!" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
