import {
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  InputNumber,
  Radio,
  Select,
  Slider,
  SliderSingleProps,
} from "antd";
import { useEffect, useState } from "react";
import "../styles.scss";

import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KycStatusInfo from "../../../../components/kycInformation";
import API from "../../../../config/api";
import { changeMetalTypeFormat } from "../../../../shared/helpers/functions";
import { GET } from "../../../../utlis/apiCalls";

export default function SipCalculator(props: any) {
  const navigate = useNavigate();

  const liveRate = useSelector((state: any) => state?.liveRate?.current);
  const country = useSelector((state: any) => state?.currencyInfo?.current);

  const [weight, setWeight] = useState(1);
  const [year, setYear] = useState(1);
  const [targetTenure, setTargetTenure] = useState(1);
  const [targetWeight, setTargetWeight] = useState(1);
  const { t } = useTranslation();
  const [metalType, setMetalType] = useState("gold_995");
  const [interval, setInterval] = useState(66);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);

  const [kycModal, setKYCModal] = useState(false);

  const [paymentDate, setPaymentDate] = useState<any>(dayjs());
  const [kycStatus, setKycStatus] = useState<any>();

  useEffect(() => {
    getKycDetails();
  }, []);
  const getKycDetails = async () => {
    try {
      const response: any = await GET(
        API.GET_KYC_BY_STATUS + props?.User?.id,
        null
      );
      if (response?.status) {
        setKycStatus(response?.data?.isissued);
      }
    } catch (error) {
    } finally {
    }
  };
  let sipData: any = {
    year,
    weight,
    metalType,
    interval,
    paymentDate,
  };
  const handleSipSubmit = () => {
    if (year > 0 && weight > 0) {
      if (kycStatus !== "accepted") {
        setKYCModal(true);
      } else {
        setIsModalOpen(true);
      }
    }
  };
  const onChangeWeight = (newValue: any) => {
    setWeight(newValue);
  };
  const onChangeYear = (newValue: any) => {
    setYear(newValue);
  };
  const onChangeInterval = (newValue: any) => {
    setInterval(newValue);
    setPaymentDate(
      newValue === 0
        ? ""
        : newValue === 66 || newValue === 100
        ? dayjs()
        : dayjs().format("dddd")
    );
  };
  const onChangeMetalType = (newValue: any) => {
    setMetalType(newValue.target.value);
  };
  const changeIntervalName = () => {
    //conditon to check if interval is daily
    if (interval === 0) {
      return t("day");
    }
    //conditon to check if interval is weekly
    if (interval === 33) {
      return t("week");
    }
    //conditon to check if interval is monthly
    if (interval === 66) {
      return t("month");
    }
    //conditon to check if interval is yearly
    if (interval === 100) {
      return t("year");
    }
  };
  const findWeightinInterval = () => {
    let intervalTime: any;
    //conditon to check if interval is daily
    if (interval === 0) {
      intervalTime = year * 365;
    }
    //conditon to check if interval is weekly
    if (interval === 33) {
      intervalTime = year * 52.143;
    }
    //conditon to check if interval is monthly
    if (interval === 66) {
      intervalTime = year * 12;
    }
    //conditon to check if interval is yearly
    if (interval === 100) {
      intervalTime = year;
    }
    return (weight * 1000) / intervalTime;
  };
  const SipCalculation = () => {
    const weightIninterval = findWeightinInterval();
    const liveAmount = props?.liveRate?.find(
      (item: any) =>
        item?.metal === changeMetalTypeFormat(metalType).toUpperCase()
    );
    const amount =
      weightIninterval *
      liveAmount?.totalBuyAmount *
      props?.currentInfo?.exchange_rate;
    sipData["amount"] = amount;
    return amount;
  };
  const formatter = (value: any) => {
    if (value === 0) {
      return "Daily";
    }
    if (value === 33) {
      return "Weekly";
    }
    if (value === 66) {
      return "Monthly";
    }
    if (value === 100) {
      return "Yearly";
    }
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const intervalMarks: SliderSingleProps["marks"] = {
    0: {
      style: {
        color: "#ffffffee",
      },
      label: <div>{t("daily")}</div>,
    },
    33: {
      style: {
        color: "#ffffffee",
      },
      label: <div>{t("weekly")}</div>,
    },
    66: {
      style: {
        color: "#ffffffee",
      },
      label: <div>{t("monthly")}</div>,
    },
    100: {
      style: {
        color: "#ffffffee",
      },
      label: <div>{t("yearly")}</div>,
    },
  };
  const optionsInterval: any = [
    { label: t("daily"), value: 0 },
    { label: t("weekly"), value: 33 },
    { label: t("monthly"), value: 66 },
    { label: t("yearly"), value: 100 },
  ];
  const optionsMetalType = [
    { label: "GOLD 995", value: "gold_995" },
    { label: "GOLD 9999", value: "gold_9999" },
    { label: "SILVER 999", value: "silver_999" },
  ];
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const onChangeDate = (val: any) => {
    setPaymentDate(val);
  };

  return (
    <div id={"sipCalculator"}>
      <br />
      <div className="app-SipScreen-txt3">{t("build_your_own_sip")}</div>
      <br />
      <div className="app-SipScreen-SipCalculator-metal-Radio-Btn">
        {" "}
        <div className="app-SipScreen-SipCalculator-Radio-Btn-box">
          <Radio.Group
            defaultValue="gold_995"
            options={optionsMetalType}
            size={window.innerWidth < 426 ? "small" : "large"}
            onChange={onChangeMetalType}
            value={metalType}
            optionType="button"
            buttonStyle="solid"
            style={{ border: "none" }}
          />
        </div>
      </div>
      <br />
      <Row>
        <Col>
          <div className="app-SipScreen-SipCalculator-box1">
            <div>
              <div className="d-flex justify-content-between">
                <div className="app-SipScreen-txt6">
                  {t("enter_target_weight")}
                </div>
                <InputNumber
                  className="app-SipScreen-InputNumber"
                  min={0}
                  max={50}
                  type="number"
                  style={{ width: 150 }}
                  value={weight}
                  onChange={onChangeWeight}
                  addonAfter={<div style={{ color: "#ffffffee" }}>KG</div>}
                  controls={false}
                  step="0.1"
                />
              </div>
              <Slider
                min={0}
                max={50}
                onChange={onChangeWeight}
                value={typeof weight === "number" ? weight : 0}
              />
            </div>
            {weight <= 0 ? (
              <div style={{ color: "red" }}>
                * {t("target_weight_cannot_be_zero")}
              </div>
            ) : null}

            <br />
            <div>
              <div className="d-flex justify-content-between">
                <div className="app-SipScreen-txt6">
                  {t("enter_target_tenure")}
                </div>
                <InputNumber
                  className="app-SipScreen-InputNumber"
                  min={0}
                  max={50}
                  type="number"
                  style={{ width: 150 }}
                  value={year}
                  onChange={onChangeYear}
                  addonAfter={<div style={{ color: "#ffffffee" }}>Y</div>}
                  controls={false}
                />
              </div>
              <Slider
                min={0}
                max={50}
                onChange={onChangeYear}
                value={typeof year === "number" ? year : 0}
              />
            </div>
            {year <= 0 ? (
              <div style={{ color: "red" }}>
                * {t("target_tenure_cannot_be_zero")}
              </div>
            ) : null}
            <br />
            <div>
              <div className="app-Sipscreen-SipCalculator-Selector d-flex justify-content-between">
                <div className="app-SipScreen-txt6">
                  {t("enter_deposit_time_interval")}
                </div>
                <Select
                  showSearch
                  style={{ width: 150 }}
                  optionFilterProp="children"
                  defaultValue={interval}
                  value={interval}
                  onChange={onChangeInterval}
                  filterOption={filterOption}
                  options={optionsInterval}
                />
              </div>
              {interval === 0 ? null : (
                <>
                  {interval === 66 || interval === 100 ? (
                    <>
                      <br />
                      <div className="app-Sipscreen-SipCalculator-Selector d-flex justify-content-between">
                        <div className="app-SipScreen-txt6">
                          {t("select_date")}
                        </div>
                        <DatePicker
                          placeholder={t("select_a_date")}
                          className="app-Sipscreen-modal-Input"
                          disabledDate={disabledDate}
                          onChange={(val: any) =>
                            onChangeDate(new Date(val).toISOString())
                          }
                          style={{ width: "150px" }}
                          defaultValue={dayjs()}
                        />
                      </div>
                      <br />
                    </>
                  ) : (
                    <>
                      <br />
                      <div className="app-Sipscreen-SipCalculator-Selector d-flex justify-content-between">
                        <div className="app-SipScreen-txt6">
                          {t("select_day")}
                        </div>
                        <Select
                          style={{ width: "150px", color: "#fff" }}
                          className="app-Sipscreen-modal-Input"
                          placeholder="Select day"
                          onChange={onChangeDate}
                          defaultValue={dayjs().format("dddd")}
                        >
                          <Select.Option value="monday">
                            {t("monday")}
                          </Select.Option>
                          <Select.Option value="tuesday">
                            {t("tuesday")}
                          </Select.Option>
                          <Select.Option value="wednesday">
                            {t("wednesday")}
                          </Select.Option>
                          <Select.Option value="thursday">
                            {t("thursday")}
                          </Select.Option>
                          <Select.Option value="friday">
                            {t("friday")}
                          </Select.Option>
                        </Select>
                      </div>
                      <br />
                    </>
                  )}
                </>
              )}

              <Slider
                value={interval}
                marks={intervalMarks}
                step={null}
                defaultValue={66}
                tooltip={{ formatter }}
                onChange={onChangeInterval}
                style={{ color: "#fff" }}
              />
            </div>
            <br />
            <div className="d-flex justify-content-end">
              <Button
                className="app-SIPScreen-Buttons"
                onClick={handleSipSubmit}
              >
                Confirm Purchase
              </Button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="app-SipScreen-SipCalculator-box1">
            {" "}
            <Card className="app-SipScreen-SipCalculator-box2">
              <div>
                <span className="app-SipScreen-txt3">
                  {" "}
                  {t("expected_investment_amount")} {changeIntervalName()}:
                </span>
                <br />
                <span className="app-Sipscreen-txt9">
                  {" "}
                  {SipCalculation()?.toFixed(2)}{" "}
                  {props?.currentInfo?.currency_code}
                </span>
              </div>
              <br />
              <br />
              <div>
                <span className="app-SipScreen-txt3">
                  {" "}
                  {t("estimated_weight_per_interval")} {changeIntervalName()}:
                </span>
                &nbsp;
                <br />
                <span className="app-Sipscreen-txt9">
                  {findWeightinInterval()?.toFixed(2)} {t("gram")}
                </span>
              </div>
              <br />
              <br />
              <div style={{ color: "gray" }}>
                *{t("note")} :- {t("amount_disclaimer")}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      {/* <SipCreateModal
        sipData={sipData}
        User={props?.User}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentInfo={props?.currentInfo}
        changeRefresh={props?.changeRefresh}
      /> */}
      <KycStatusInfo
        data={kycStatus}
        User={props?.User}
        isOpen={kycModal}
        onClose={() => setKYCModal(false)}
        currentInfo={props?.currentInfo}
        changeRefresh={props?.changeRefresh}
      />
    </div>
  );
}
