import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GET } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
import { useSelector } from "react-redux";

function Section2() {
    const [isLoading, setisLoading] = useState<any>();
    const [kycStatusType, setKycStatusType] = useState<string>();
    const [kycDetails, setkycDetails] = useState<any>(null);
    const userdetails = useSelector((state: any) => state.auth.user);
    let userId = userdetails.id;
    const getkycDetails = async () => {
      try {
        setisLoading(true);
        const response: any = await GET(`${API.GET_KYC_BY_ID}${userId}`, null);
        if (response?.statusCode !== 404) {
          setKycStatusType(response?.isissued);
          setkycDetails(response);
        }
      } catch (error) {
        console.error("Error fetching KYC details:", error);
      } finally {
        setisLoading(false);
      }
    };
    useEffect(() => {
      getkycDetails();
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
}

  return (
    <div className="app-ProfileScreen-Section2">
      <div className="app-ProfileScreen-Section1-Txt2">Bank details</div>
      <br />
      <Row>
      <Col md={6} xs={12}>
      <div className="app-ProfileScreen-Section2-box1">
            {/* <div className="app-ProfileScreen-Section2-Box2">
              Name : &nbsp;&nbsp;
             <span className="app-ProfileScreen-UserForm-Box2-txt1">
               LUCKYSTAR GOLD TRADING L.L.C
               </span>
           </div>
            <div className="app-ProfileScreen-Section2-Box2">
               A/C NO : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
               0373180410001
             </span>
             </div>
             <div className="app-ProfileScreen-Section2-Box2">
              IBAN : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                AE510400000373180410001
              </span>
             </div>
             <div className="app-ProfileScreen-Section2-Box2">
              SWIFT CODE : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                 NRAKAEAKKXXX
               </span>
             </div>
            <div className="app-ProfileScreen-Section2-Box2">
             BANK : &nbsp;&nbsp;
               <span className="app-ProfileScreen-UserForm-Box2-txt1">
                 RAK BANK
              </span>
             </div> */}
                    {/* User's bank details */}
                  
                        <div className="app-ProfileScreen-Section2-Box2">
                            Name: &nbsp;&nbsp;
                            <span className="app-ProfileScreen-UserForm-Box2-txt1">
                                {kycDetails?.bankName || ""}
                            </span>
                        </div>
                        <div className="app-ProfileScreen-Section2-Box2">
                            A/C NO: &nbsp;&nbsp;
                            <span className="app-ProfileScreen-UserForm-Box2-txt1">
                                {kycDetails?.accountNumber || ""}
                            </span>
                        </div>
                        {/* <div className="app-ProfileScreen-Section2-Box2">
                            IBAN: &nbsp;&nbsp;
                            <span className="app-ProfileScreen-UserForm-Box2-txt1">
                                {kycDetails?.iban || ""}
                            </span>
                        </div> */}
                        <div className="app-ProfileScreen-Section2-Box2">
                            SWIFT CODE: &nbsp;&nbsp;
                            <span className="app-ProfileScreen-UserForm-Box2-txt1">
                                {kycDetails?.ifscCode || ""}
                            </span>
                        </div>
                        <div className="app-ProfileScreen-Section2-Box2">
                        BRANCH: &nbsp;&nbsp;
                            <span className="app-ProfileScreen-UserForm-Box2-txt1">
                                {kycDetails?.branch || ""}
                            </span>
                        </div>
                    </div>
                </Col>
        <Col md={6} xs={12}>
          <div className="app-ProfileScreen-Section2-box1">
            <div className="app-ProfileScreen-Section2-Box2">
              Name : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                LUCKYSTAR GOLD TRADING L.L.C
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              A/C NO : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                80309100026813
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              IBAN : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                AE070150080309100026813
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              SWIFT CODE : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                BMISAEAAXXX
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              BRANCH : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                BANQUE MISR
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Section2;
