import React from "react";
import { Row, Col } from "react-bootstrap";
import { Card, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../routes/pageHeader";
import { SettingOutlined } from "@ant-design/icons";
import { Cascader, InputNumber, Select, Space } from "antd";
import API from "../../../../config/api";
import { PUT } from "../../../../utlis/apiCalls";
import TextArea from "antd/es/input/TextArea";

function KycVerificationcreate() {
  const { id } = useParams();
  console.log(id);
  const { t } = useTranslation();
  const [form] = useForm();

  const { Option } = Select;

  const selectBefore = (
    <Select defaultValue="add" style={{ width: 60 }}>
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  );

  // const fetchData = async (val: any) => {
  //   try {
  //     const response: any = await PUT(
  //       API.
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <>
      <div className="kycAddContainer">
        <PageHeader title={"Edit KYC Details"} />
        <div>
          <Card>
            <Form form={form}>
              <Row>
                <Col md={12}>
                  <div className="app-KycScreen-Form-Txt1">
                    {t("individual_details")}
                  </div>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("fullname")}:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                      {
                        pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                        message: t("please_enter_valid_name"),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Name"
                      size="large"
                      className="inputstyle"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("phone_number")} : </label>
                  <Form.Item
                    name={"mobile"}
                    rules={[
                      {
                        required: true,
                        message: t("Phone_number_is_required"),
                      },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (!/^[0-9]{6,10}$/.test(value)) {
                            return Promise.reject(
                              t("Please_enter_a_valid_phone_number")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {/* <Input
                    placeholder={t("enter_phone_number")}
                    className="inputstyle"
                  /> */}
                    <InputNumber
                      placeholder={t("enter_phone_number")}
                      className="inputstyle w-100"
                      addonBefore={selectBefore}
                      defaultValue={100}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("pin_code")} : </label>
                  <Form.Item
                    name={"pinCode"}
                    rules={[
                      {
                        pattern: /^[\d\s]+$/, // Allows only numeric digits and spaces
                        message: t("please_enter_valid_number"),
                      },
                      {
                        max: 10,
                        message: t("number_length_must_be_less_than_10"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_pin_code")}
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("email")}</label>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                      {
                        type: "email",
                        message: t("please_enter_valid_email"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_email")}
                      type="email"
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("address")}</label>
                  <Form.Item name={"address"} required>
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <div className="app-KycScreen-Form-Txt1">
                    {t("bank_details")}
                  </div>
                </Col>

                <Col md={6}>
                  <label className="mb-2"> {t("bank_name")} :</label>
                  <Form.Item
                    name={"bankName"}
                    rules={[
                      {
                        required: true,
                        pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                        message: t("please_enter_valid_name"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_bank_name")}
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("bank_account_name")} :</label>
                  <Form.Item
                    name={"bankAccountName"}
                    rules={[
                      {
                        required: true,
                        message: "enter account name",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("bank_account_name")}
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2">{t("account_no")} :</label>
                  <Form.Item
                    name={"accountNumber"}
                    rules={[
                      {
                        required: true,
                        message: "enter account number",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("account_no")}
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2"> {t("ifsc_code")} :</label>
                  <Form.Item
                    name={"ifscCode"}
                    rules={[
                      {
                        required: true,
                        message: "enter ifsc code",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_ifsc_code")}
                      className="inputstyle"
                      style={{ textTransform: "uppercase" }}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <label className="mb-2"> {t("branch")} :</label>
                  <Form.Item
                    name={"branch"}
                    rules={[
                      {
                        required: true,
                        message: "enter branch",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_branch")}
                      className="inputstyle"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default KycVerificationcreate;
