import { Tag } from "antd";
import { Col, Row } from "react-bootstrap";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  changeMetalTypeFormat,
  metalColor,
  StatusColor,
} from "../../../../shared/helpers/functions";

function ListItems(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="app-Sipscreen-DataTable-ListItems">
      <Row
        className="g-2 w-100"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Col md={3}>
          <Tag
            style={{
              backgroundColor: "transparent",
              color: metalColor(props?.data?.metal_type),
              border: `1px solid ${metalColor(props?.data?.metal_type)}`,
              padding: "5px",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {changeMetalTypeFormat(props?.data?.metal_type)}
          </Tag>
          <div className="app-Sipscreen-DataTable-txt1">
            {t("current_weight")}&nbsp;:&nbsp;
            <span>
              {props?.data?.weight ? props?.data?.weight.toFixed(2) : "0"}
            </span>
          </div>
          <div className="app-Sipscreen-DataTable-txt1">
            {t("target_weight")}&nbsp;:&nbsp;
            <span>
              <Tag
                style={{
                  backgroundColor: "#00a56e49",
                  color: "#00a56e",
                  border: "none",
                  padding: "5px",
                  fontSize: "16px",
                }}
              >
                {props?.data?.target_weight}
              </Tag>
            </span>
          </div>
        </Col>
        <Col md={2}>
          <div className="app-Sipscreen-DataTable-txt1">
            {`${props?.data?.interval} up to ${props?.data?.tenure} years`}
          </div>
        </Col>
        <Col md={2}>
          <div className="app-Sipscreen-DataTable-txt1">
           {t("payment_issued")}:<br />
            {moment(props?.data?.payment_date).format("D MMM, YYYY")}
          </div>
        </Col>
        <Col md={2}>
          <div className="app-Sipscreen-DataTable-txt1">
            <Tag
              //   color={StatusColor(props?.data?.status)}
              style={{
                backgroundColor: "transparent",
                color: StatusColor(props?.data?.status),
                padding: "5px",
                fontSize: "16px",
                border: `1px solid ${StatusColor(props?.data?.status)}`,
              }}
            >
              {props?.data?.status}
            </Tag>
          </div>
        </Col>
        <Col md={2}>
          <div className="app-Sipscreen-DataTable-txt1">
            {t("Created_On")}:<br />
            {moment(props?.data?.createdAt).format("D MMM, YYYY")}
          </div>
        </Col>
        <Col md={1}>
          <div className="app-Sipscreen-DataTable-txt1">
            <MdOutlineRemoveRedEye
              size={30}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/auth/sipDetails/${props?.data?.id}`)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ListItems;
