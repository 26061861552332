import { MdOutlineEdit } from "react-icons/md";

import { Col, Row } from "react-bootstrap";
import { AiOutlineLogin } from "react-icons/ai";

import profile from "../../../../assets/images/emptyProfile.jpg";
import { useNavigate } from "react-router-dom";

function Section1(props: any) {
  let navigate = useNavigate();
  return (
    <div>
      <div className="app-ProfileScreen-Section1-box1">
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <div className="app-ProfileScreen-Section1-box2"></div>
          <div>User details</div>
        </div>
        <br />
        <Row
          style={{
            marginBottom: "5px",
            width: "100%",
          }}
        >
          <Col md={2} xs={12}>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props?.data?.image ? (
                <div
                  className="app-ProfileScreen-box1-profile"
                  style={{
                    backgroundImage: `url(${props?.data?.image})`,
                  }}
                >
                  <div
                    className="app-ProfileScreen-box1-profile-Edit"
                    onClick={props?.EditProfile}
                  >
                    <MdOutlineEdit color="#ffffffee" />
                  </div>
                </div>
              ) : (
                <div
                  className="app-ProfileScreen-box1-profile"
                  style={{
                    backgroundImage: `url(${profile})`,
                  }}
                >
                  <div
                    className="app-ProfileScreen-box1-profile-Edit"
                    onClick={props?.EditProfile}
                  >
                    <MdOutlineEdit color="#ffffffee" />
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={7} xs={12}>
            <Row className="w-100 ">
              <div style={{ marginBottom: "20px" }}>
                {props?.data?.name || "N/A"}
              </div>
              <Col md={4} xs={12}>
                <div className="app-ProfileScreen-Section1-Txt1">
                  Phone Number <br />
                  <span className="app-ProfileScreen-Section1-Txt2">
                    {props?.data?.phoneNumber}
                  </span>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="app-ProfileScreen-Section1-Txt1">
                  Email
                  <br />
                  <span className="app-ProfileScreen-Section1-Txt2">
                    {props?.data?.email}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100 g-2">
          <Col md={3} xs={12}>
            <div className="app-ProfileScreen-Section1-box6">
              <div className="app-ProfileScreen-Section1-box7">
                <div className="app-ProfileScreen-Section1-box7-icon">
                  <AiOutlineLogin size={30} />
                </div>
              </div>
              <div
                onClick={() => navigate("/auth/transactionLog")}
                style={{ cursor: "pointer" }}
              >
                Transactions <br />
                <span
                  onClick={() => navigate("/auth/transactionLog")}
                  style={{ cursor: "pointer" }}
                  className="app-ProfileScreen-Section1-Txt1"
                >
                  Transaction screen
                </span>
              </div>
            </div>
          </Col>
          <Col md={3} xs={12}>
            <div className="app-ProfileScreen-Section1-box6">
              <div className="app-ProfileScreen-Section1-box7">
                <div className="app-ProfileScreen-Section1-box7-icon">
                  <AiOutlineLogin size={30} />
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/auth/sendGift");
                }}
              >
                Send gift <br />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/auth/sendGift");
                  }}
                  className="app-ProfileScreen-Section1-Txt1"
                >
                  Gift screen
                </span>
              </div>
            </div>
          </Col>
          <Col md={3} xs={12}>
            <div className="app-ProfileScreen-Section1-box6">
              <div className="app-ProfileScreen-Section1-box7">
                <div className="app-ProfileScreen-Section1-box7-icon">
                  <AiOutlineLogin size={30} />
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/auth/sip");
                }}
              >
                Sip <br />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/auth/sip");
                  }}
                  className="app-ProfileScreen-Section1-Txt1"
                >
                  Sip screen
                </span>
              </div>
            </div>
          </Col>
          <Col md={3} xs={12}>
            <div className="app-ProfileScreen-Section1-box6">
              <div className="app-ProfileScreen-Section1-box7">
                <div className="app-ProfileScreen-Section1-box7-icon">
                  <AiOutlineLogin size={30} />
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/auth/redeem")}
              >
                Radeem <br />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/auth/redeem")}
                  className="app-ProfileScreen-Section1-Txt1"
                >
                  Radeem screen
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Section1;
