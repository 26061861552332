import React from "react";
import "../styles.scss";

import { Col, Row } from "react-bootstrap";

import { FaRegEdit } from "react-icons/fa";

function UserForm(props: any) {
  return (
    <div className="app-ProfileScreen-box2">
      <Row className="app-ProfileScreen-UserForm-Box1">
        <Col md={10} xs={10}>
          <div className="app-ProfileScreen-UserForm-Box2">
            Name : &nbsp;&nbsp;
            <span className="app-ProfileScreen-UserForm-Box2-txt1">
              {props?.data?.name}
            </span>
          </div>
        </Col>
        <Col md={2} xs={2}>
          <FaRegEdit
            size={20}
            color="gray"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onEdit(true);
              props?.onSetFeild("name");
            }}
          />
        </Col>
      </Row>
      <Row className="app-ProfileScreen-UserForm-Box1">
        <Col md={10} xs={10}>
          <div className="app-ProfileScreen-UserForm-Box2">
            Phone Number : &nbsp;&nbsp;
            <span className="app-ProfileScreen-UserForm-Box2-txt1">
              {props?.data?.phoneNumber}
            </span>
          </div>
        </Col>
        <Col md={2} xs={2}>
          {" "}
          <FaRegEdit
            size={20}
            color="gray"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onEdit(true);
              props?.onSetFeild("phone");
            }}
          />
        </Col>
      </Row>
      <Row className="app-ProfileScreen-UserForm-Box1">
        <Col md={10} xs={10}>
          <div className="app-ProfileScreen-UserForm-Box2">
            Email : &nbsp;&nbsp;
            <span className="app-ProfileScreen-UserForm-Box2-txt1">
              {props?.data?.email} 
            </span>
          </div> 
        </Col>
        <Col md={2} xs={2}>
          {" "}
          <FaRegEdit
            size={20}
            color="gray"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onEdit(true);
              props?.onSetFeild("email");
            }}
          />
        </Col>
      </Row>
      {/* <Row className="app-ProfileScreen-UserForm-Box1">
        <Col md={10} xs={10}>
          <div className="app-ProfileScreen-UserForm-Box2">
            City : &nbsp;&nbsp;
            <span className="app-ProfileScreen-UserForm-Box2-txt1">
              {props?.data?.city}
            </span>
          </div>
        </Col>
        <Col md={2} xs={2}>
          {" "}
          <FaRegEdit
            size={20}
            color="gray"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onEdit(true);
              props?.onSetFeild("city");
            }}
          />
        </Col>
      </Row> */}
    </div>
  );
}

export default UserForm;
