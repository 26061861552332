import { HiUserCircle } from "react-icons/hi";
import Clock from "react-live-clock";
import { useSelector, useDispatch } from "react-redux";
import { Popover, message } from "antd";
import { IoLogOutOutline } from "react-icons/io5";
import { isLoggedOut } from "../../Redux/slice/authSlice";
// import { useHandleTokenExpiration } from "../../components/CommonHeader/helpers/fetchingDatas";


function Header() {
  const User = useSelector((state: any) => state.auth);
  console.log("user---", User);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(isLoggedOut());
    message.success({
      type: "loading",
      content: "Logout Successfully..",
      duration: 2,
    });
  };
  // const handleTokenExpiration = useHandleTokenExpiration();

  const profile = (
    <div className="px-2">
      <div className=" border-bottom border-2 pb-2 px-3">
        <div className="d-flex justify-content-center">
          <HiUserCircle size={65} color="#dbdbdb" />
        </div>
        <div className="text-center">{User.user.name}</div>
        <div className="text-center">{User.user.email}</div>
      </div>
      <div className="logoutbtn text-center mt-2" onClick={logout}>
        Logout <IoLogOutOutline color="red" size={25} />
      </div>
    </div>
  );
  return (
    <div className="AdminRoutes-Header">
      <div className="AdminRoutes-HeaderBox">
        <div className="AdminRoutes-Headertxt1">Admin Panel</div>
        <div style={{ flex: 1 }}></div>
        <div className="AdminRoutes-HeaderTimeBox">
          <Clock format={"h:mm:ss A"} ticking={true} />
        </div>
        <div className="AdminRoutes-HeaderBox2">
          <Popover content={profile} trigger="click">
            <HiUserCircle size={40} color="#dbdbdb" />
          </Popover>
          {/* <div>
            <HiUserCircle size={40} color="#dbdbdb" />
          </div> */}
          <div style={{ marginLeft: 10 }}>
            <div className="AdminRoutes-Headertxt2">{User.user.name}</div>
            <div className="AdminRoutes-Headertxt3">admin</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
